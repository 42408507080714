@import "./variables.scss";
@import "./mixins.scss";

.home-content {
  display: grid;
  grid:
    "left left" auto
    "right right" auto /
    1fr 1fr;
  height: 100vh;
  @include media-breakpoint-range(tab-landscape-up) {
    grid:
      "left right" auto /
      1fr 1fr;
  }

  .image-panel {
    grid-area: left;
    height: 100%;
    background-image: url(/assets/img/boat.jpg);
    background-size: cover;
    background-position: center;
    position: relative;
    padding: 120px 20px 20px;
    background-blend-mode: multiply;
    background-color: #6d6d6d;

    @include media-breakpoint-range(tab-landscape-up) {
      padding: 40px;
    }

    .hero-message {
      bottom: 0;
      z-index: 1;
      color: $light-text;
      margin-bottom: 40px;
      position: relative;
      @include media-breakpoint-range(tab-landscape-up) {
        position: absolute;
      }

      h1 {
        font-size: 34px;
        color: white;
        margin-bottom: 40px;
        text-align: center;
        @include media-breakpoint-range(tab-landscape-up) {
          font-size: 40px;
          text-align: left;
        }
      }

      p {
        width: auto;
        max-width: none;
        font-size: 19px;
        line-height: 30px;
        letter-spacing: 0.5px;
        margin-bottom: 40px;
        text-align: center;
        @include media-breakpoint-range(tab-landscape-up) {
          text-align: left;
          max-width: 74%;
        }
      }

      .actions {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        @include media-breakpoint-range(tab-up) {
          flex-direction: row;
        }
        @include media-breakpoint-range(tab-landscape-up) {
          justify-content: flex-start;
        }
        a {
          cursor: pointer;
          margin-bottom: 15px;
          margin-left: 8px;
          margin-right: 8px;
          text-align: center;
          @include media-breakpoint-range(tab-landscape-up) {
            margin-bottom: 16px;
            margin-right: 16px;
            margin-left: 0;
          }
        }
      }
    }

    .copyrights {
      color: $secondary-text;
      font-size: 12px;
      text-transform: uppercase;
      margin-top: 70px;
      display: none;
      @include media-breakpoint-range(tab-landscape-up) {
        display: block;
      }
    }

    .gradient {
      position: absolute;
      opacity: 0.5;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($color: #000000, $alpha: .75);
      @include media-breakpoint-range (tab-landscape-up) {
        background: rgba(0, 0, 0, 0);
        background: -moz-linear-gradient(
          top,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 100%
        );
        background: -webkit-gradient(
          left top,
          left bottom,
          color-stop(0%, rgba(0, 0, 0, 0)),
          color-stop(100%, rgba(0, 0, 0, 1))
        );
        background: -webkit-linear-gradient(
          top,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 100%
        );
        background: -o-linear-gradient(
          top,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 100%
        );
        background: -ms-linear-gradient(
          top,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 100%
        );
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
      }
    }
  }

  .facts-panel {
    grid-area: right;
    height: 100%;
    padding: 20px;
    display: grid;
    grid:
      "text text" auto
      "slides slides" auto
      "social social" 20px
      / 1fr 1fr;

    @include media-breakpoint-range(tab-landscape-up) {
      padding: 60px 40px 40px;
      grid:
        "text slides" calc(100% - 20px)
        "social social" 20px
        / 1.5fr 1fr;
    }

    .text-content {
      grid-area: text;
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-evenly;
      padding-bottom: 20px;
      @include media-breakpoint-range (tab-landscape-up) {
        padding-bottom: 0;
      }
      .fact {
        h2 {
          font-size: 34px;
          margin-top: 0;
          text-align: center;
          margin-bottom: 10px;
          @include media-breakpoint-range(tab-landscape-up) {
            margin-bottom: 0;
            text-align: left;
            font-size: 40px;
          }
        }
        
        p {
          font-size: 19px;
          line-height: 30px;
          color: $secondary-text;
          margin-bottom: 5px;
          text-align: center;
          @include media-breakpoint-range(tab-landscape-up) {
            text-align: left;
            margin-bottom: 0;
          }
        }
      }
    }

    .slider {
      grid-area: slides;
      max-width: 300px;
      display: flex;
      align-items: center;
      margin: auto;

      @include media-breakpoint-range (tab-landscape-up) {
        margin: 0;
      }

      .slide_item {
        img {
          width: auto;
          max-width: 300px;
        }
      }
    }

    .social-icons {
      grid-area: social;
      display: flex;
      justify-content: center;
      @include media-breakpoint-range (tab-landscape-up) {
        justify-content: flex-end;
      }

      a {
        display: inline-block;
        margin-left: 16px;
      }
    }
  }
}
