
$primary-color: #3ab54a;
$secondary-color: #2b3991;
$danger-color: #ed1b23;
$primary-text: darken($color: $secondary-color, $amount: 25);
$secondary-text: #ab8383;
$light-text: #e4e4e4;
$support-color: #fbb040;

$font-primary: 'Fjalla One', sans-serif;
$font-secondary: 'Nunito', sans-serif;
